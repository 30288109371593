html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
	box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}
ol,
ul {
	list-style: none;
}
blockquote,
q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: "";
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
a {
	text-decoration: none !important;
	color: #0b093b;
}
a:hover {
	color: #3119aa;
}
input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0 50px #fff inset; /* Change the color to your own background color */
	box-shadow: transparent;
	-webkit-text-fill-color: #fff;
}
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&family=Sree+Krushnadevaraya&display=swap&text=0123456789");
body {
	background: #fff;
	color: #0b093b;
	font-family: "Space Grotesk", "Raleway", sans-serif;
	overflow-x: hidden;
	font-size: 16px;
	font-weight: 400;
}
.container {
	width: 100%;
	max-width: 1220px;
	margin: 0 auto;
	padding: 0px 30px;
}
@media only screen and (max-width: 859px) {
	.container {
		padding: 0 15px;
	}
}
.regular {
	font-weight: 400 !important;
}
.semiBold {
	font-weight: 600 !important;
}
.extraBold {
	font-weight: 800 !important;
}

/* HELLPERS */
.textCenter {
	text-align: center;
}
.animate {
	transition: 0.3s ease;
}
.pointer {
	cursor: pointer;
}
.relative {
	position: relative;
}
.textCenter {
	text-align: center;
}
.textLeft {
	text-align: left;
}
.textRight {
	text-align: right;
}
.x100 {
	width: 100%;
}
.active {
	border-bottom: 2px solid #3119aa;
	color: #3119aa;
}
.tag {
	padding: 5px 10px;
	margin: 10px 0;
}
.shadow {
	box-shadow: 0 0 11px rgba(237, 232, 237, 0.5);
	-webkit-box-shadow: 0 0 11px rgba(237, 232, 237, 0.5);
	-moz-box-shadow: 0 0 11px rgba(237, 232, 237, 0.5);
}
/* FLEXBOX */
.flex {
	display: flex;
}
.flexSpaceCenter {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.flexNullCenter {
	display: flex;
	align-items: center;
}
.flexColumn {
	flex-direction: column;
}
.flexCenter {
	display: flex;
	justify-content: center;
	align-items: center;
}
.flexSpaceNull {
	display: flex;
	justify-content: space-between;
}

/* FONT SIZES */
.font11 {
	font-size: 0.688rem;
}
.font12 {
	font-size: 0.75rem;
}
.font13 {
	font-size: 0.813rem;
}
.font14 {
	font-size: 0.875rem;
}
.font15 {
	font-size: 0.938rem;
}
.font18 {
	font-size: 1.125rem;
}
.font20 {
	font-size: 1.25rem;
}
.font25 {
	font-size: 1.563rem;
}
.font30 {
	font-size: 1.875rem;
}
.font40 {
	font-size: 2.5rem;
}
.font60 {
	font-size: 3.75rem;
	line-height: 4.5rem;
}
@media only screen and (max-width: 859px) {
	.font11 {
		font-size: 0.688rem;
	}
	.font12 {
		font-size: 0.688rem;
	}
	.font13 {
		font-size: 0.75rem;
	}
	.font14 {
		font-size: 0.813rem;
	}
	.font15 {
		font-size: 0.813rem;
	}
	.font18 {
		font-size: 0.938rem;
	}
	.font20 {
		font-size: 1.125rem;
	}
	.font25 {
		font-size: 1.25rem;
	}
	.font30 {
		font-size: 1.5rem;
	}
	.font40 {
		font-size: 1.875rem;
	}
	.font60 {
		font-size: 2.8rem;
	}
}
/* BORDER RADIUS */
.radius8 {
	border-radius: 1rem;
}
.radius6 {
	border-radius: 0.6rem;
}
/* COLORS */
.darkColor {
	color: #0b093b;
}
.purpleColor {
	color: #3119aa;
}
.orangeColor {
	color: #f2b300;
}
.lightColor {
	color: #f5f5f5;
}
.coralColor {
	color: #c4ffff;
}
.greenColor {
	color: #49cb86;
}
.greyColor {
	color: #707070;
}
.whiteColor {
	color: #fff;
}
/* BACKGROUNDS */
.darkBg {
	background-color: #0b093b;
}
.purpleBg {
	background-color: #3119aa;
}
.orangeBg {
	background-color: #f2b300;
}
.lightBg {
	background-color: #f5f5f5;
}
.coralBg {
	background-color: #c4ffff;
}
.greenBg {
	background-color: #49cb86;
}
.greyBg {
	background-color: #707070;
}
.whiteBg {
	background-color: #fff;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.login-gradient-animation {
	background: linear-gradient(-45deg, #f2b300, #49cb86, #3119aa, #e3170a);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	width: 45%;
	height: 100%;
}

.complaint-gradient-animation {
	background: linear-gradient(-45deg, #5c6bc0, #a860bb, #6c5eb3, #df837d);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	width: 5%;
	height: 100%;
	-webkit-transition: 300ms linear;
	-moz-transition: 300ms linear;
	-o-transition: 300ms linear;
	-ms-transition: 300ms linear;
	transition: 300ms linear;
}

.complaint-gradient-animation-expanded {
	width: 45%;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.fadeIn {
	animation: fadeIn 200ms;
}

/* Loaders Start */

/**
  * Flip to square
  *
  * @author jh3y - jheytompkins.com
*/
@-webkit-keyframes flip-to-square {
	0% {
		-webkit-transform: rotateX(-90deg);
		transform: rotateX(-90deg);
	}
	50%,
	75% {
		-webkit-transform: rotateX(0);
		transform: rotateX(0);
	}
	100% {
		opacity: 0;
		-webkit-transform: rotateX(0);
		transform: rotateX(0);
	}
}
@keyframes flip-to-square {
	0% {
		-webkit-transform: rotateX(-90deg);
		transform: rotateX(-90deg);
	}
	50%,
	75% {
		-webkit-transform: rotateX(0);
		transform: rotateX(0);
	}
	100% {
		opacity: 0;
		-webkit-transform: rotateX(0);
		transform: rotateX(0);
	}
}

.flip-to-square {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(3, 1fr);
	height: 60px;
	width: 60px;
}
.flip-to-square div {
	-webkit-animation: flip-to-square 1.5s calc(var(--delay) * 1s) infinite backwards;
	animation: flip-to-square 1.5s calc(var(--delay) * 1s) infinite backwards;
	background-color: var(--primary);
}
.flip-to-square div:nth-child(1) {
	--delay: 0.1;
}
.flip-to-square div:nth-child(2) {
	--delay: 0.2;
}
.flip-to-square div:nth-child(3) {
	--delay: 0.3;
}
.flip-to-square div:nth-child(4) {
	--delay: 0.4;
}
.flip-to-square div:nth-child(5) {
	--delay: 0.5;
}
.flip-to-square div:nth-child(6) {
	--delay: 0.6;
}
.flip-to-square div:nth-child(7) {
	--delay: 0.7;
}
.flip-to-square div:nth-child(8) {
	--delay: 0.8;
}
.flip-to-square div:nth-child(9) {
	--delay: 0.9;
}

/**
  * Scaling dots
  *
  * @author jh3y
*/
@-webkit-keyframes scaling-dots {
	0%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	40%,
	60% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
@keyframes scaling-dots {
	0%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	40%,
	60% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

.scaling-dots {
	display: grid;
	height: 10px;
	grid-gap: 5px;
	grid-template-columns: repeat(5, 10px);
}
.scaling-dots div {
	-webkit-animation: scaling-dots 1s infinite ease backwards;
	animation: scaling-dots 1s infinite ease backwards;
	background: var(--primary);
	border-radius: 100%;
	height: 10px;
	width: 10px;
}
.scaling-dots div:nth-child(1) {
	-webkit-animation-delay: 0.1s;
	animation-delay: 0.1s;
}
.scaling-dots div:nth-child(2) {
	-webkit-animation-delay: 0.2s;
	animation-delay: 0.2s;
}
.scaling-dots div:nth-child(3) {
	-webkit-animation-delay: 0.3s;
	animation-delay: 0.3s;
}
.scaling-dots div:nth-child(4) {
	-webkit-animation-delay: 0.4s;
	animation-delay: 0.4s;
}
.scaling-dots div:nth-child(5) {
	-webkit-animation-delay: 0.5s;
	animation-delay: 0.5s;
}

/* Loaders End */

/* 404 Start */

.not-found-main {
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: center;
	text-align: center;
	background-color: #0b093b;
}

.not-found-h1 {
	color: #ededed;
	font-size: 12.5rem;
	letter-spacing: 0.1em;
	margin: 0.025em 0;
	text-shadow: 0.05em 0.05em 0 rgba(0, 0, 0, 0.25);
	white-space: nowrap;
	margin-bottom: 2%;

	@media (max-width: 30rem) {
		font-size: 8.5rem;
	}

	& > span {
		animation: spooky 2s alternate infinite linear;
		color: #ededed;
		display: inline-block;
		margin-right: 20px;
	}
}

.not-found-h2 {
	color: #eeeeff;
	margin-bottom: 0.4em;
}

.not-found-p {
	color: #eeeeff;
	margin-top: 0.4em;
}

@keyframes spooky {
	from {
		transform: translatey(0.15em) scaley(0.95);
	}

	to {
		transform: translatey(0em);
	}
}

/* 404 End */

/* Tumbleweed Start */
.tumbleweed-empty-icon-container {
	margin: 1em auto;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: "Dosis", sans-serif;
}
.tumbleweed-animation-container {
	position: relative;
	display: block;
	text-align: center;
	height: 100px;
	width: 250px;
	border-bottom: solid 2px #333;
	overflow: hidden;
}
.tumbleweed-bounce {
	display: inline-block;
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 40px;
	height: 40px;
	margin-left: -20px;
	background-size: contain;
	animation: bounceAnim 1s cubic-bezier(0.63, 0.09, 0.75, 0.46) infinite alternate, spinAnim 3s linear infinite;
	background-image: url("../assets/img/tumbleweed.png");
}

@keyframes bounceAnim {
	0%,
	10% {
		bottom: 50%;
	}

	100% {
		bottom: 0%;
	}
}
@keyframes spinAnim {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.tumbleweed-pebble1 {
	position: absolute;
	top: 95%;
	background-color: #333;
	width: 10px;
	height: 10px;
	border-radius: 20px 20px 0px 0px;
	animation: pebblesAnim 1s linear infinite;
}
.tumbleweed-pebble2 {
	position: absolute;
	top: 97%;
	background-color: #333;
	width: 5px;
	height: 5px;
	border-radius: 10px 10px 0px 0px;
	animation: pebblesAnim 2s linear infinite;
}
.tumbleweed-pebble3 {
	position: absolute;
	top: 98%;
	background-color: #333;
	width: 3px;
	height: 3px;
	border-radius: 20px 20px 0px 0px;
	animation: pebblesAnim 3s linear infinite;
}

@keyframes pebblesAnim {
	0% {
		right: -20%;
	}
	100% {
		right: 110%;
	}
}
/* Tumbleweed End */

/* Disable arrows on number textfield */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

/* Custom */

.blur {
	filter: blur(8px);
	-webkit-filter: blur(8px);
}

.elevate {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 2;
}

.center {
	align-items: center;
	justify-content: center;
}

.unauth-background {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	background-color: #05203d;
}

.unauth-paper {
	width: 80%;
	height: 80vh;
	box-shadow: 20;
	background-color: #ededed;
}

.unauth-center {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.complaint-center {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	overflow: auto;
	padding-left: 20px;
	padding-right: 20px;
	position: relative;
}

.complaint-gradient {
	padding: 30px 30px 30px 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.complaint-stepper-container {
	position: absolute;
	top: 0;
	margin-top: 20px;
	width: 100%;
	height: 100px;
}

.break-line-typography {
	display: inline-block;
	white-space: pre-line;
}

.hide {
	display: none;
}

.complaint-field-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	/* height: 100%; */
	position: absolute;
	top: 100px;
	margin-top: 30px;
	padding: 0px 20px 0px 20px;
}

.align-items-center {
	display: flex;
	align-items: center;
}

.file-upload-container {
	display: flex;
	align-items: center;
}

.file-upload-button {
	margin-top: 6px !important;
}

.slick-custom {
	& .slick-list {
		& .slick-track {
			display: flex;
			align-items: center;
		}
	}
}

.wrap-text {
	display: block;
	text-align: justify;
	overflow-wrap: break-word;
	word-wrap: break-word;
	width: 100%;
}
